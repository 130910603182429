
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 47,
            //             phoneElementId:59,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Wheeled Inspection Robot',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP67',
            //                     sort: 3
            //                 }, {
            //                     descName: '≥6h',
            //                     sort: 4
            //                 }, {
            //                     descName: '1.5m/s',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Protection Level',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Endurance Time',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Running Speed',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 48,
            //             phoneElementId: 60,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Wheeled Inspection Robot',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Equipped with navigation and perception devices, it can replace or assist manual inspection in a fully autonomous or semi autonomous manner. It can collect and intelligently analyze on-site environmental data in real time, achieve automatic diagnosis of equipment defects, intelligent operation and maintenance of environmental facilities, production safety protection, hidden danger investigation and other functions, significantly reduce the danger index and labor intensity of frontline high-risk workers, and comprehensively improve the digital operation and maintenance capabilities of enterprises.',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 49,
            //             phoneElementId: 61,
            //             descList: [
            //                 {
            //                     descName: 'Application Scenarios',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Explosion-proof Wheeled Inspection Robot can be applied in industries such as petroleum, chemical, natural gas, and power industry, and commonly used in intelligent inspection business scenarios of oil, gas, fields, pipelines, tanks and other various flammable, explosive, toxic, and harmful collection stations, chemical plants or storage areas.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId:50,
            //             phoneElementId: 62,
            //             descList: [
            //                 {
            //                     descName: 'Meter Recognition Reading',
            //                     sort: 1
            //                 }, {
            //                     descName: 'By using AI visual technology to read data from various instruments and meters, if the collected data exceeds the threshold, an alert will be generated.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 51,
            //             phoneElementId: 63,
            //             descList: [
            //                 {
            //                     descName: 'Leakage Detection',
            //                     sort: 1
            //                 }, {
            //                     descName: 'By using AI visual identification technology and other means for leakage detection, the coordinates of the leakage location can be displayed and an early warning can be given.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 52,
            //             phoneElementId: 64,
            //             descList: [
            //                 {
            //                     descName: 'Unmanned Autonomous Inspection',
            //                     sort: 1
            //                 }, {
            //                     descName: 'No manual intervention is required, automatic inspection, manual inspection, abnormal inspection and automatic inspection along the track can be realized.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 53,
            //             phoneElementId: 65,
            //             descList: [
            //                 {
            //                     descName: 'Intelligent Obstacle Avoidance Function',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Equipped with obstacle avoidance sensors, safe operation, obstacle avoidance and early warning are achieved through Lidar and anti-collision sensors.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcElementId:54,
            //             phoneElementId: 66,
            //             descList: [
            //                 {
            //                     descName: 'Infrared Temperature Measurement of Equipment',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Equipped with infrared thermal imager, it can collect the surface temperature of the device and give early warning for abnormal temperature.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcElementId: 55,
            //             phoneElementId:67,
            //             descList: [
            //                 {
            //                     descName: 'Ambient Gas Monitoring',
            //                     sort: 1
            //                 }, {
            //                     descName: 'It can monitor flammable, explosive, toxic and harmful gases such as oxygen, carbon monoxide, combustible gas and hydrogen sulfide in real time.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcElementId: 56,
            //             phoneElementId: 68,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Wheeled Inspection Robot',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: 'Specification',
            //                     sort: 1
            //                 },  {
            //                     descName: 'Dimension (L×W×H)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: 'Walking Speed',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: 'Obstacle Surmounting Ability',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Charging Time',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: 'Main Navigation Mode',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: 'Curb Weight',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: 'Protection Level',
            //                     sort: 9
            //                 }, {
            //                     descName: 'Gradeability',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: 'Wading Depth',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: 'Battery Endurance',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: 'Repetitive Navigation Positioning Accuracy',
            //                     sort: 13
            //                 }, {
            //                     descName: '≤1420mm×820mm×1055mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'Ex d IIB T4Gb -Ex d IIC T6 Gb',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '0-1.5m/s，Speed Adjustable',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '120mm',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '2h to 80% (Fast Charging)',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: 'SLAM Laser Navigation and SLAM Laser Positioning',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '350kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: 'IP67',
            //                     sort: 21
            //                 }, {
            //                     descName: '≤25°',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '200mm',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '≥6h at Speed 1m/S, ≥24h when Standby',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '≤±2cm',
            //                     sort: 25
            //                 }],
            //              type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcElementId: 57,
            //             phoneElementId: 69,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Wheeled Inspection Robot',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Reduce the risk index',
            //                     sort: 2
            //                 }, {
            //                     descName: 'Enhance the digital operation and maintenance capabilities of enterprises',
            //                     sort: 3
            //                 }],
            //              type: 'image',
            //             sort: 11
            //         }
            //     ],
            elmentList:[]
        }
    },

    created() {
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
            this.$router.push('/pdfView')
        },
    }
}